<ng-container *ngIf="result">
  <p-contextMenu #cm [model]="contextMenu" />
  <p-table
    #dt
    [columns]="columns"
    [value]="result.nodes"
    [loading]="loading"
    [dataKey]="dataKey"
    [totalRecords]="result.totalCount"
    [lazy]="true"
    (onLazyLoad)="nextPage($event)"
    [first]="skip"
    (firstChange)="table.skip$.next($event)"
    [rows]="take"
    (rowsChange)="table.take$.next($event)"
    [sortField]="sortField"
    [sortOrder]="sortOrder"
    [rowsPerPageOptions]="table.rowsPerPageOptions"
    [paginator]="hidePaginator !== false"
    editMode="row"
    [responsiveLayout]="responsiveLayout"
    columnResizeMode="expand"
    [breakpoint]="'900px'"
    [rowHover]="true"
    [contextMenu]="contextMenu ? cm : undefined"
    [(contextMenuSelection)]="contextMenuItem">
    <ng-template pTemplate="caption">
      <div class="table-caption">
        <div *ngIf="countHeaderTranslation" class="table-caption-table-info">
          <div class="table-caption-text">
            <ng-container *ngIf="!loading"
              >{{ skip > 0 ? skip : 1 }} {{ 'common.until' | translate }}
              {{ skip + result.count }} {{ 'common.of' | translate }}
              {{ result.totalCount }}
            </ng-container>
            {{ countHeaderTranslation | translate }}
          </div>
        </div>

        <div
          *ngIf="sortable || filterable"
          class="table-caption-btn-wrapper btn-wrapper">
          <div class="flex gap-5">
            <ng-container *ngIf="hasPermissions.add">
              <ng-container *ngIf="addActionTemplate; else addBtn">
                <ng-container
                  *ngTemplateOutlet="addActionTemplate"></ng-container>
              </ng-container>
              <ng-template #addBtn>
                <p-button
                  *ngIf="add.observed && editable"
                  tooltipPosition="left"
                  pTooltip="{{ 'common.add' | translate }}"
                  class="icon-btn btn"
                  icon="pi pi-plus"
                  (onClick)="addNewNode()"
                  [disabled]="isEditingNew">
                </p-button>
              </ng-template>
            </ng-container>

            <div class="flex">
              <app-multi-select-hidden-columns
                [columns]="columns"
                [table]="name">
              </app-multi-select-hidden-columns>
              <p-button
                *ngIf="sortable"
                tooltipPosition="left"
                pTooltip="{{ 'common.reset_sort' | translate }}"
                icon="pi pi-sort-alt-slash"
                class="icon-btn btn"
                (onClick)="resetSort(dt)">
              </p-button>
              <p-button
                *ngIf="filterable"
                tooltipPosition="left"
                pTooltip="{{ 'common.reset_filters' | translate }}"
                icon="pi pi-filter-slash"
                class="icon-btn btn"
                (onClick)="resetFilters()">
              </p-button>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template pTemplate="header" let-cols>
      <tr>
        <ng-container *ngFor="let column of cols">
          <th
            *ngIf="column.visible"
            [pSortableColumn]="column.sortable ? column.name : undefined"
            [ngClass]="column.size ? 'table-header-' + column.size : ''">
            <div class="table-header-label" *ngIf="column.visible">
              <div class="table-header-text">
                {{ column.translation | translate }}
              </div>
              <p-sortIcon
                *ngIf="column.sortable"
                [field]="column.name"
                class="table-header-icon"></p-sortIcon>
            </div>
          </th>
        </ng-container>
        <th
          *ngIf="customActionTemplate || edit.observed || delete.observed"
          class="table-header-actions">
          <div class="table-header-label">
            <div class="table-header-text">
              {{ 'common.actions' | translate }}
            </div>
          </div>
        </th>
      </tr>

      <tr>
        <ng-container *ngFor="let column of cols">
          <th
            *ngIf="
              column.visible &&
              column.fuzzyFilterColumns &&
              column.name === column.fuzzyFilterColumns[0]
            "
            [attr.colspan]="getFuzzyColspan(column.fuzzyFilterColumns)">
            <form *ngIf="filterForm" [formGroup]="filterForm">
              <input
                type="text"
                pInputText
                formControlName="fuzzy"
                placeholder="{{ 'checkin.fuzzy' | translate }}" />
            </form>
          </th>
          <th
            *ngIf="column.visible && !column.fuzzyFilterColumns"
            [ngClass]="{ 'table-header-{{column.size}}': column.size }">
            <form *ngIf="filterForm" [formGroup]="filterForm">
              <ng-container
                *ngIf="
                  column.filterable &&
                  (column.type === TableColumnType.STRING ||
                    column.type === TableColumnType.COMMENT ||
                    column.type === TableColumnType.ID ||
                    column.type === TableColumnType.PLAYER_ID)
                ">
                <input
                  type="text"
                  pInputText
                  [formControlName]="column.name"
                  placeholder="{{ column.translation | translate }}" />
              </ng-container>
              <ng-container
                *ngIf="
                  column.filterable && column.type === TableColumnType.NUMBER
                ">
                <input
                  type="number"
                  min="0"
                  pInputText
                  [formControlName]="column.name"
                  placeholder="{{ column.translation | translate }}" />
              </ng-container>
              <ng-container
                *ngIf="
                  column.filterable && column.type === TableColumnType.BOOLEAN
                ">
                <p-triStateCheckbox [formControlName]="column.name">
                </p-triStateCheckbox>
              </ng-container>
              <ng-container
                *ngIf="
                  column.filterable && column.type === TableColumnType.DATE
                ">
                <input
                  type="text"
                  pInputText
                  [formControlName]="column.name"
                  placeholder="{{ 'common.date' | translate }}" />
              </ng-container>
              <ng-container
                *ngIf="
                  column.filterable && column.type === TableColumnType.DROPDOWN
                ">
                <p-dropdown
                  *ngIf="!column.dropdownOptions?.multiple; else multi"
                  [formControlName]="column.name"
                  dataKey="id"
                  [options]="
                    column.dropdownOptions?.options ??
                    (dropdownOptions[column.name] | async)
                  "
                  [showClear]="true"
                  [dataKey]="column.dropdownOptions?.dataKey"
                  [optionLabel]="column.dropdownOptions?.optionLabel"
                  [optionValue]="column.dropdownOptions?.optionValue"
                  [placeholder]="column.translation | translate"
                  [filter]="column.dropdownOptions?.filterable"
                  [filterBy]="column.dropdownOptions?.filterBy"
                  (onShow)="openDropdown(column)"></p-dropdown>

                <ng-template #multi>
                  <p-multiSelect
                    [name]="column.name"
                    [formControlName]="column.name"
                    [options]="
                      column.dropdownOptions?.options ??
                      (dropdownOptions[column.name] | async)
                    "
                    [dataKey]="column.dropdownOptions?.dataKey"
                    [optionLabel]="column.dropdownOptions?.optionLabel"
                    [optionValue]="column.dropdownOptions?.optionValue"
                    [placeholder]="column.translation | translate"
                    (onPanelShow)="openDropdown(column)">
                  </p-multiSelect>
                </ng-template>
              </ng-container>
              <ng-container
                *ngIf="
                  column.filterable && column.type === TableColumnType.TEAM
                ">
                <p-dropdown
                  formControlName="team"
                  dataKey="id"
                  [options]="dropdownOptions[column.name] | async"
                  optionLabel="name"
                  optionValue="name"
                  placeholder="{{ 'common.team' | translate }}"
                  [filter]="true"
                  filterBy="name"
                  (onShow)="openDropdown(column)"></p-dropdown>
              </ng-container>
              <ng-container
                *ngIf="
                  column.filterable &&
                  column.type === TableColumnType.ATTENDANCE
                ">
                <p-dropdown
                  formControlName="attendance"
                  dataKey="id"
                  [options]="dropdownOptions[column.name] | async"
                  optionLabel="name"
                  optionValue="name"
                  placeholder="{{ 'common.team' | translate }}"
                  [filter]="true"
                  filterBy="name"
                  (onShow)="openDropdown(column)"></p-dropdown>
              </ng-container>
            </form>
          </th>
        </ng-container>
        <th
          *ngIf="customActionTemplate || edit.observed || delete.observed"></th>
      </tr>
    </ng-template>

    <ng-template
      pTemplate="body"
      let-item
      let-editing="editing"
      let-ri="rowIndex"
      let-cols="columns">
      <tr
        [pEditableRow]="inlineEdit && editable ? item : undefined"
        [pContextMenuRow]="contextMenu ? item : undefined"
        [ngClass]="{
          disabled: rowDisabled ? rowDisabled(item) : false,
          pointer: onRowClick || rowRouterLink,
        }"
        [routerLink]="rowRouterLink ? rowRouterLink(item) : undefined"
        [queryParamsHandling]="rowRouterLink ? 'merge' : undefined"
        (click)="onRowClick ? onRowClick(item) : undefined">
        <ng-container *ngFor="let column of cols">
          <td *ngIf="column.visible">
            <span class="p-column-title"
              >{{ column.translation | translate }}:</span
            >
            <app-table-column
              [column]="column"
              [value]="
                column.valueGetter !== undefined
                  ? column.valueGetter(item)
                  : item
              "
              [rowIndex]="ri"
              [inlineEdit]="inlineEdit"
              [isEditing]="isEditing"
              [isEditingNew]="isEditingNew">
              <ng-template customColumn let-item>
                <ng-container
                  *ngTemplateOutlet="
                    customColumnTemplate;
                    context: { $implicit: item, column: column }
                  "></ng-container>
              </ng-template>
            </app-table-column>
          </td>
        </ng-container>
        <td
          *ngIf="
            customActionTemplate ||
            add.observed ||
            edit.observed ||
            delete.observed
          ">
          <span class="p-column-title"
            >{{ 'common.actions' | translate }}:</span
          >

          <div class="btn-wrapper">
            <ng-container *ngIf="customActionTemplate">
              <ng-container
                *ngTemplateOutlet="
                  customActionTemplate;
                  context: { $implicit: item }
                "></ng-container>
            </ng-container>

            <ng-container *ngIf="hasPermissions.edit">
              <ng-container *ngIf="editActionTemplate; else editBtn">
                <ng-container
                  *ngTemplateOutlet="
                    editActionTemplate;
                    context: { $implicit: item }
                  "></ng-container>
              </ng-container>
              <ng-template #editBtn>
                <p-button
                  *ngIf="edit.observed && !editing"
                  pInitEditableRow
                  class="btn icon-btn"
                  icon="pi pi-pencil"
                  (click)="$event.stopPropagation(); onRowEditInit(item, ri)"
                  [disabled]="rowDisabled && rowDisabled(item)"></p-button>
              </ng-template>
            </ng-container>

            <ng-container *ngIf="hasPermissions.delete">
              <ng-container *ngIf="deleteActionTemplate; else deleteBtn">
                <ng-container
                  *ngTemplateOutlet="
                    deleteActionTemplate;
                    context: { $implicit: item }
                  "></ng-container>
              </ng-container>
              <ng-template #deleteBtn>
                <p-button
                  *ngIf="delete.observed && !editing"
                  class="btn icon-btn danger-icon-btn"
                  icon="pi pi-trash"
                  (click)="
                    $event.stopPropagation();
                    logger.debug('trigger delete');
                    delete.emit(item)
                  "
                  [disabled]="rowDisabled && rowDisabled(item)"></p-button>
              </ng-template>
            </ng-container>

            <p-button
              *ngIf="editing"
              pSaveEditableRow
              class="btn icon-btn"
              icon="pi pi-check-circle"
              (click)="
                $event.stopPropagation(); onRowEditSave(item, ri)
              "></p-button>
            <p-button
              *ngIf="editing"
              pCancelEditableRow
              class="btn icon-btn danger-icon-btn"
              icon="pi pi-times-circle"
              (click)="
                $event.stopPropagation(); onRowEditCancel(ri)
              "></p-button>
          </div>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr></tr>
      <tr>
        <td colspan="10">{{ 'common.no_entries_found' | translate }}</td>
      </tr>
      <tr></tr>
    </ng-template>
  </p-table>
</ng-container>
